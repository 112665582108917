import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { NavLink, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { hasSMSBlocked } from 'client-lib/src/lib/utils/helpers';
import THEMES from '../../../../styles/themes/app';
import {
  Heading3,
  Text,
  IconButton,
  IconBorderButton,
  InlineTextButton,
} from '../../../../elements';
import {
  setActiveCreateEmailSection,
  setActiveCreateFaxSection,
  setContactSlideoutAutofocusField,
} from '../../../../actions/general';
import { setDefaultContact } from '../../../../actions/uploadModal';
import useMeasure from '../../../Animations/useMeasure';
import isSMSNotCompatible from '../../../../utils/helpers/isSMSNotCompatible';

const HeaderContainer = styled.div`
  display: flex;
  max-height: 178px;
  min-height: 120px;
  border-bottom: 1px solid ${THEMES.BORDER_COLOR};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 12px;
`;

const MainHeaderContainer = styled.div`
  text-align: center;
  max-width: 250px;
  display: flex;
  flex-direction: row;
  column-gap: 2px;
  align-items: flex-end;
  padding-bottom: 4px;
`;

const SubHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  max-width: 500px;
  text-align: center;
  padding-bottom: 12px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 4px;
  column-gap: 8px;
`;

const TopButtonWidthContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  padding-top: 16px;
`;

const TopButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 12px;
`;

const CustomerContactCardHeaderContainer = styled.div``;

const CustomerContactCardHeader = ({
  header,
  companyName,
  handleClickOnEdit,
  closeCustomerInfo,
  customerContact,
  startContactThread,
  CustomerInfoOptionsTestPrep,
  hasFaxChannel,
  hasEmailChannel,
  hasSmsChannel,
}) => {
  const history = useHistory();
  const [bind, measure] = useMeasure();

  const dispatch = useDispatch();
  const faxFeatureAvailable = useSelector(
    (state) => state?.accountData?.account?.ff_fax
  );

  const emailFeatureAvailable = useSelector(
    (state) => state?.accountData?.account?.ff_email
  );

  const onBlockOrDelete = () => {
    const { pathname } = history.location;
    if (pathname.includes('/threads/open/')) {
      history.push('/threads/open/');
    } else if (pathname.includes('/threads/inbox/')) {
      history.push('/threads/inbox/');
    } else {
      history.push('/contacts/all/');
    }
  };

  const bubbleDisplace = measure.width - 122;
  const contentBubbleDisplace = measure.width - 246;

  const isSMSActionDisabled = (customerContact) =>
    isSMSNotCompatible(customerContact.smsStatus) ||
    !hasSmsChannel ||
    customerContact?.conversationsOptOut ||
    hasSMSBlocked(customerContact);

  const onEmailButtonClick = () => {
    closeCustomerInfo();
    dispatch(setDefaultContact(customerContact));
    dispatch(setActiveCreateEmailSection());
  };

  const onFaxButtonClick = () => {
    closeCustomerInfo();
    dispatch(setDefaultContact(customerContact));
    dispatch(setActiveCreateFaxSection());
  };

  return (
    <CustomerContactCardHeaderContainer>
      <TopButtonWidthContainer {...bind}>
        <TopButtonContainer>
          {CustomerInfoOptionsTestPrep && (
            <CustomerInfoOptionsTestPrep
              key={customerContact.id}
              customer={customerContact}
              onBlock={onBlockOrDelete}
              onDelete={onBlockOrDelete}
              adjustCarrotOverride={{ displayOptions: 54, contentMode: 178 }}
              optionsBubbleOverride={{
                displayOptions: bubbleDisplace,
                contentMode: contentBubbleDisplace,
              }}
            />
          )}
          <IconButton onClick={closeCustomerInfo} size="lg">
            <i className="ri-close-fill" />
          </IconButton>
        </TopButtonContainer>
      </TopButtonWidthContainer>
      <HeaderContainer>
        <MainHeaderContainer>
          <Heading3 maxWidth="275px">{header}</Heading3>
          <IconButton
            onClick={() => {
              handleClickOnEdit(true);
            }}
            size="sm"
            contrast="highColor"
          >
            <i className="ri-pencil-fill" />
          </IconButton>
        </MainHeaderContainer>
        <SubHeaderContainer>
          {customerContact?.jobTitle ? (
            <Text contrast="low" maxWidth="200px">
              {customerContact?.jobTitle}
            </Text>
          ) : (
            <InlineTextButton
              type="EmphasisText"
              noOutline
              onClick={() => {
                dispatch(setContactSlideoutAutofocusField('jobTitle'));
                handleClickOnEdit();
              }}
            >
              {i18n.t('customers-Contacts-assignTitle', {
                defaultValue: 'Assign JobTitle',
              })}
            </InlineTextButton>
          )}
          <Text contrast="low">|</Text>
          {companyName ? (
            <Text contrast="low" maxWidth="200px">
              {companyName}
            </Text>
          ) : (
            <InlineTextButton
              type="EmphasisText"
              noOutline
              onClick={() => {
                dispatch(setContactSlideoutAutofocusField('company'));
                handleClickOnEdit();
              }}
            >
              {i18n.t('slideouts-CustomerInfo-assignCompany', {
                defaultValue: 'Assign Company',
              })}
            </InlineTextButton>
          )}
        </SubHeaderContainer>
        <ButtonsContainer>
          <IconBorderButton
            disabled={isSMSActionDisabled(customerContact)}
            type="secondaryLight"
            onClick={() => startContactThread(customerContact)}
          >
            <i className="ri-question-answer-fill" />
          </IconBorderButton>
          {emailFeatureAvailable && (
            <IconBorderButton
              type="secondaryLight"
              onClick={onEmailButtonClick}
              disabled={!hasEmailChannel}
            >
              <i className="ri-mail-fill" />
            </IconBorderButton>
          )}

          {faxFeatureAvailable && (
            <IconBorderButton
              onClick={onFaxButtonClick}
              type="secondaryLight"
              disabled={!hasFaxChannel}
            >
              <i className="ri-printer-fill" />
            </IconBorderButton>
          )}

          <NavLink
            to={`/contacts/all/${
              customerContact?.id
            }/${customerContact?.__typename?.toLowerCase()}`}
          >
            <IconBorderButton>
              <i className="ri-contacts-book-2-fill" />
            </IconBorderButton>
          </NavLink>
        </ButtonsContainer>
      </HeaderContainer>
    </CustomerContactCardHeaderContainer>
  );
};

CustomerContactCardHeader.propTypes = {
  header: PropTypes.string.isRequired,
  companyName: PropTypes.string,
  handleClickOnEdit: PropTypes.func.isRequired,
  closeCustomerInfo: PropTypes.func.isRequired,
  inActiveThreadGroup: PropTypes.bool.isRequired,
  customerContact: PropTypes.object,
  startContactThread: PropTypes.func.isRequired,
  hasEmailChannel: PropTypes.bool.isRequired,
  hasFaxChannel: PropTypes.bool.isRequired,
  hasSmsChannel: PropTypes.bool.isRequired,
  CustomerInfoOptionsTestPrep: PropTypes.elementType,
};

CustomerContactCardHeader.defaultProps = {
  companyName: '',
  customerContact: {},
  CustomerInfoOptionsTestPrep: null,
};

export default CustomerContactCardHeader;
