import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import i18n from 'i18n-js';
import {
  contactName,
  formatPhoneNumber,
} from 'client-lib/src/lib/utils/helpers';
import theme from 'styled-theming';
import {
  Text,
  Heading5,
  AppendageList,
  SquareBadge,
  Tooltip,
} from '../../../../elements';
import THEMES from '../../../../styles/themes/app';
import {
  PriorityNotificationSection,
  NotesUpdatedAt,
  NotesWrap,
  newlineStringLiteralIntoBreak,
  GroupDisplayWrap,
  sectionItemCustomStyle,
} from '../CustomerInfo';

const NOTES_FONTSIZE = theme('fontSize', {
  default: '12px',
  large: '16px',
});

const PreferencesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const IconWrapper = styled.div`
  font-size: 16px;
  color: ${(props) =>
    props.optedIn ? THEMES.THEME_GREEN(props) : THEMES.FOREGROUND_LOW(props)};
`;

const AccountRepHeaderWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
`;

export const CustomerContactPhoneNumber = ({ customerContact }) => (
  <div data-testid="customer-info-phone-contact">
    <Heading5 contrast="high">
      {i18n.t('slideouts-CustomerInfo-phone')}
    </Heading5>
    <Text contrast="med" customStyle={sectionItemCustomStyle}>
      {formatPhoneNumber(customerContact?.phoneNumber)}
    </Text>
  </div>
);

CustomerContactPhoneNumber.propTypes = {
  customerContact: PropTypes.object.isRequired,
};

export const CustomerContactFax = ({ customerContact }) => (
  <div data-testid="customer-info-fax-contact">
    <Heading5 contrast="high">{i18n.t('slideouts-CustomerInfo-fax')}</Heading5>
    <Text contrast="med" customStyle={sectionItemCustomStyle}>
      {formatPhoneNumber(customerContact?.faxNumber)}
    </Text>
  </div>
);

CustomerContactFax.propTypes = {
  customerContact: PropTypes.object.isRequired,
};

export const CustomerContactEmail = ({ customerContact, hovering = false }) => (
  <div
    data-testid="customer-info-email-contact"
    style={hovering ? { width: 'calc(100% - 20px)' } : { width: '100%' }}
  >
    <Heading5 contrast="high">
      {i18n.t('slideouts-CustomerInfo-email')}
    </Heading5>
    <Text contrast="med" customStyle={sectionItemCustomStyle}>
      {customerContact?.emailAddress}
    </Text>
  </div>
);

CustomerContactEmail.propTypes = {
  customerContact: PropTypes.object.isRequired,
  hovering: PropTypes.bool,
};
CustomerContactEmail.defaultProps = {
  hovering: false,
};

export const CustomerContactGroups = ({
  customerContact,
  accountGroups,
  hovering = false,
}) => (
  <GroupDisplayWrap
    data-testid="customer-info-groups-contact"
    style={hovering ? { width: 'calc(100% - 20px)' } : null}
  >
    <Heading5 contrast="high">
      {i18n.t('slideouts-CustomerInfo-groups')}
    </Heading5>
    {customerContact?.groupIds &&
    customerContact?.groupIds.length &&
    accountGroups ? (
      <AppendageList
        list={customerContact?.groupIds?.map((id) => ({ id }))}
        listItemRender={({ id }) => accountGroups?.[id]?.name}
        displayDelete={() => false}
      />
    ) : null}
  </GroupDisplayWrap>
);

CustomerContactGroups.propTypes = {
  customerContact: PropTypes.object.isRequired,
  accountGroups: PropTypes.object.isRequired,
  hovering: PropTypes.bool,
};
CustomerContactGroups.defaultProps = {
  hovering: false,
};

export const CustomerContactAccountRep = ({
  customerContact,
  priorityNotificationsViewPermission,
}) => (
  <div
    data-testid="customer-info-account-rep"
    style={{ width: 'calc(100% - 20px)', position: 'relative' }}
  >
    <AccountRepHeaderWrap>
      <Heading5 contrast="high">
        {i18n.t('slideouts-CustomerInfo-accountRep')}
      </Heading5>
      {customerContact?.repUser && priorityNotificationsViewPermission ? (
        <PriorityNotificationSection>
          <Text
            contrast="high"
            customStyle={() =>
              sectionItemCustomStyle({
                smallFont: true,
                noMargin: true,
              })
            }
          >
            {i18n.t('slideouts-CustomerInfo-priority')}
            &#58;&nbsp;
          </Text>
          <SquareBadge
            color={customerContact?.prioritizeRep ? 'green' : 'grey'}
          >
            {customerContact?.prioritizeRep
              ? i18n.t('slideouts-CustomerInfo-enabled')
              : i18n.t('slideouts-CustomerInfo-disabled')}
          </SquareBadge>
        </PriorityNotificationSection>
      ) : null}
    </AccountRepHeaderWrap>
    <Text contrast="med" customStyle={sectionItemCustomStyle}>
      {customerContact?.repUser ? contactName(customerContact?.repUser) : ''}
    </Text>
  </div>
);

CustomerContactAccountRep.propTypes = {
  customerContact: PropTypes.object.isRequired,
  priorityNotificationsViewPermission: PropTypes.bool.isRequired,
};

export const CustomerContactNotes = ({ customerContact }) => (
  <div
    data-testid="customer-info-notes"
    style={{ maxWidth: '95%', width: '95%' }}
  >
    <Heading5 contrast="high">
      {i18n.t('slideouts-CustomerInfo-notes')}
    </Heading5>
    {customerContact?.notesUpdatedAt ? (
      <NotesUpdatedAt>
        {i18n.t('slideouts-CustomerInfo-lastModified', {
          date: i18n.strftime(
            new Date(customerContact.notesUpdatedAt),
            '%-m/%d/%Y'
          ),
        })}
      </NotesUpdatedAt>
    ) : null}
    {!customerContact?.notes ? (
      <Text
        contrast="low"
        customStyle={(props) => `font-size: ${NOTES_FONTSIZE(props)};`}
      >
        {i18n.t('slideouts-CustomerInfo-nothingToSee')}
      </Text>
    ) : (
      <NotesWrap>
        <Text
          contrast="med"
          data-testid="customer-info-notes-text"
          dangerouslySetInnerHTML={{
            __html: newlineStringLiteralIntoBreak(customerContact?.notes),
          }}
        />
      </NotesWrap>
    )}
  </div>
);

CustomerContactNotes.propTypes = {
  customerContact: PropTypes.object.isRequired,
};

export const CustomerContactOptIn = ({
  customerContact,
  optInOutEditPermission,
  apiMessageFeatureEnabled,
  allowEmailsOptOut,
  allowFaxOptOut,
  promoAccess,
}) => (
  <div style={{ width: '100%' }}>
    <div
      style={{
        width: 'calc(100% - 22px)',
        position: 'relative',
      }}
    >
      <PreferencesWrapper>
        <Heading5 contrast="high">
          {i18n.t('settings-Preferences-header')}
        </Heading5>

        <Tooltip
          elementsContent={
            <Text>{i18n.t('slideouts-Customers-conversationalText')}</Text>
          }
          place="top"
          id="conversational-top-tip"
        >
          <IconWrapper
            optedIn={!customerContact?.conversationsOptOut}
            data-tip
            data-for="conversational-top-tip"
          >
            <i className="ri-question-answer-fill" />
          </IconWrapper>
        </Tooltip>
        {apiMessageFeatureEnabled && (
          <Tooltip
            elementsContent={
              <Text>{i18n.t('slideouts-CustomerInfo-autoMessages')}</Text>
            }
            place="top"
            id="api-top-tip"
          >
            <IconWrapper
              optedIn={!customerContact?.apiOptOut}
              data-tip
              data-for="api-top-tip"
            >
              <i className="ri-terminal-box-fill" />
            </IconWrapper>
          </Tooltip>
        )}

        {optInOutEditPermission ? (
          <>
            <Tooltip
              elementsContent={
                <Text>{i18n.t('settings-SettingsNavigation-broadcasts')}</Text>
              }
              place="top"
              id="broadcast-top-tip"
            >
              <IconWrapper
                optedIn={customerContact?.announcementsOptOut === false}
                data-tip
                data-for="broadcast-top-tip"
              >
                <i className="ri-base-station-fill" />
              </IconWrapper>
            </Tooltip>
            {promoAccess && (
              <Tooltip
                elementsContent={
                  <Text>
                    {i18n.t('settings-broadcasts-promotionalBroadcast')}
                  </Text>
                }
                place="top"
                id="promo-broadcast-top-tip"
              >
                <IconWrapper
                  optedIn={
                    customerContact?.promotionalBroadcastOptOut === false
                  }
                  data-tip
                  data-for="promo-broadcast-top-tip"
                >
                  <i className="ri-advertisement-fill" />
                </IconWrapper>
              </Tooltip>
            )}
          </>
        ) : null}

        {allowEmailsOptOut ? (
          <Tooltip
            elementsContent={
              <Text>{i18n.t('slideouts-CreateCustomerForm-email')}</Text>
            }
            place="top"
            id="email-top-tip"
          >
            <IconWrapper
              optedIn={!customerContact?.emailsOptOut}
              data-tip
              data-for="email-top-tip"
            >
              <i className="ri-mail-fill" />
            </IconWrapper>
          </Tooltip>
        ) : null}

        {allowFaxOptOut ? (
          <Tooltip
            elementsContent={
              <Text>{i18n.t('slideouts-CreateCustomerForm-fax')}</Text>
            }
            place="top"
            id="fax-top-tip"
          >
            <IconWrapper
              optedIn={!customerContact?.faxOptOut}
              data-tip
              data-for="fax-top-tip"
            >
              <i className="ri-printer-fill" />
            </IconWrapper>
          </Tooltip>
        ) : null}
      </PreferencesWrapper>
    </div>
  </div>
);

CustomerContactOptIn.propTypes = {
  customerContact: PropTypes.object.isRequired,
  optInOutEditPermission: PropTypes.bool.isRequired,
  allowEmailsOptOut: PropTypes.bool.isRequired,
  allowFaxOptOut: PropTypes.bool.isRequired,
  apiMessageFeatureEnabled: PropTypes.bool.isRequired,
  promoAccess: PropTypes.bool,
};

CustomerContactOptIn.defaultProps = {
  promoAccess: false,
};
