import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import DetectClickOutside from './DetectClickOutside';
import THEMES from '../../styles/themes/app';
import BUBBLE_THEMES from '../../styles/themes/library/bubble';

const Wrapper = styled.div`
  position: ${(props) => (props.fixedPosition ? 'fixed' : 'relative')};
  z-index: ${(props) => (props.zIndex ? props.zIndex : '0')};
`;

const OptionsBubble = styled.div`
  position: absolute;
  ${(props) => (props.flipYAxis ? 'bottom' : 'top')}: ${(props) => props.top};
  right: ${(props) =>
    props.horizontallyStatic
      ? '0px'
      : -(
          props.carrotPlacementRight +
          props.carrotBorderWidth * 2 -
          props.bubbleLeft
        ) + 'px'};

  background-color: ${THEMES.BACKGROUND_PRIMARY};
  color: ${THEMES.FOREGROUND_HIGH};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : 4)}px;
  box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid ${THEMES.BORDER_COLOR};
  min-width: ${(props) => props.bubbleMinWidth}px;
  ${(props) => props.bubbleMaxWidth && `max-width: ${props.bubbleMaxWidth}`};
`;

const Carrot = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border-width: ${(props) => props.carrotBorderWidth}px;
  border-top-width: 0;
  border-color: transparent;
  border-style: solid;
  right: ${(props) => props.carrotPlacementRight}px;
  ${(props) => (props.flipYAxis ? 'bottom' : 'top')}: ${(props) => props.top};
  border-bottom-color: ${(props) => props.backgroundColor};
  ${(props) => (props.flipYAxis ? 'transform: scaleY(-1);' : null)}
`;

/*

  INSTRUCTIONS FOR ALIGNMENT OF Bubble COMPONENT:

  1. use prop moveLeftVal to align the carrot horizontally where you want it (usually in the center of a button/element when active)
  2. adjust the bubble position from there using prop moveBubbleRight.

  note: I know the vals say "right" and "left", but the engineer should know that the user can pass negative values to these props as well, thus turning
  "right" into "left" and vice versa

*/

const Bubble = ({
  isOpen,
  onClickOutside,
  moveBubbleRightVal,
  moveLeftVal,
  bubbleMaxWidth,
  bubbleMinWidth,
  top,
  children,
  fixedPosition,
  zIndex,
  id,
  flipYAxis,
  dataTestId,
  hasCarrot,
  highlightCarrot,
  borderRadius,
  horizontallyStatic,
  classesNotOutside,
}) => {
  const CARROT_BORDER_WIDTH = 11;
  const CARROT_PLACEMENT_RIGHT = 50 + moveBubbleRightVal;
  const BUBBLE_WIDTH = bubbleMinWidth || 250;

  return isOpen ? (
    <Wrapper fixedPosition={fixedPosition} zIndex={zIndex} id={id}>
      <DetectClickOutside
        onClickOutside={onClickOutside}
        classesNotOutside={classesNotOutside}
      >
        <OptionsBubble
          bubbleMinWidth={BUBBLE_WIDTH}
          borderRadius={borderRadius}
          bubbleMaxWidth={bubbleMaxWidth}
          carrotPlacementRight={CARROT_PLACEMENT_RIGHT}
          carrotBorderWidth={CARROT_BORDER_WIDTH}
          bubbleLeft={moveLeftVal}
          top={top}
          flipYAxis={flipYAxis}
          horizontallyStatic={horizontallyStatic}
          data-testid={dataTestId}
        >
          {hasCarrot ? (
            <>
              <Carrot
                top="-12px"
                backgroundColor={THEMES.BORDER_COLOR}
                carrotPlacementRight={CARROT_PLACEMENT_RIGHT}
                carrotBorderWidth={CARROT_BORDER_WIDTH}
                flipYAxis={flipYAxis}
              />
              <Carrot
                top="-11px"
                backgroundColor={
                  highlightCarrot
                    ? BUBBLE_THEMES.SELECTED_STATE
                    : THEMES.BACKGROUND_PRIMARY
                }
                carrotPlacementRight={CARROT_PLACEMENT_RIGHT}
                carrotBorderWidth={CARROT_BORDER_WIDTH}
                flipYAxis={flipYAxis}
              />
            </>
          ) : null}

          {children}
        </OptionsBubble>
      </DetectClickOutside>
    </Wrapper>
  ) : null;
};

Bubble.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClickOutside: PropTypes.func.isRequired,
  moveBubbleRightVal: PropTypes.number,
  moveLeftVal: PropTypes.number,
  bubbleMaxWidth: PropTypes.number,
  bubbleMinWidth: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
  top: PropTypes.string,
  fixedPosition: PropTypes.bool,
  zIndex: PropTypes.number,
  borderRadius: PropTypes.number,
  id: PropTypes.string,
  flipYAxis: PropTypes.bool,
  dataTestId: PropTypes.string,
  hasCarrot: PropTypes.bool,
  highlightCarrot: PropTypes.bool,
  horizontallyStatic: PropTypes.bool,
  classesNotOutside: PropTypes.array,
};

Bubble.defaultProps = {
  moveBubbleRightVal: 0,
  borderRadius: null,
  moveLeftVal: 0,
  bubbleMaxWidth: null,
  bubbleMinWidth: null,
  top: '25px',
  fixedPosition: false,
  zIndex: 0,
  id: '',
  flipYAxis: false,
  dataTestId: null,
  hasCarrot: true,
  horizontallyStatic: false,
  highlightCarrot: false,
  classesNotOutside: [],
};

export default Bubble;
